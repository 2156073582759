import axios from 'axios'
import { decode } from '@/services/decode'

const GetToken = () => {
  if (localStorage.hasOwnProperty('User')) {
    const data = JSON.parse(decode(localStorage.getItem('User')))
    const auth = {
      headers: { Authorization: `Bearer ${data.token}` }
    }
    return auth
  }
}

export default {
  // Get Data for Me
  async GetDataMe (val) {
    // console.log('เข้า 1')
    const auth = await GetToken()
    // console.log(auth)
    try {
      var response = await axios.get(`${process.env.VUE_APP_API}users/` + val, auth)
      // console.log('response', response)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  // Get Data List User
  async GetDataListUSer () {
    // console.log('เข้า 2')
    const auth = await GetToken()
    const User = JSON.parse(decode(localStorage.getItem('User')))
    var response
    try {
      if (User.user.role === 'USER') {
        response = await axios.get(`${process.env.VUE_APP_API}users?role=USER`, auth)
      } else if (User.user.role === 'EXPERT') {
        response = await axios.get(`${process.env.VUE_APP_API}users?role=EXPERT`, auth)
      }
      // console.log('response', response)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  // viewUserLogs บันทึก Logs การเปิดดู Profile
  async SetProfileLog (val) {
    const auth = await GetToken()
    var data = {
      userId: val.userId,
      viewUserId: val.viewUserId
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}viewUserLogs`,  data, auth)
      console.log('response', response)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  // viewUserLogs/isView ตรวจสอบ Logs การเปิดดู Profile
  async GetProfileLog (val) {
    const auth = await GetToken()
    var data = {
      userId: val.userId,
      viewUserId: val.viewUserId
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_API}viewUserLogs/isView`,  data, auth)
      // console.log('response', response)
      return response
    } catch (error) {
      return error.response.data
    }
  }
}