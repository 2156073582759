import Vue from "vue";
import Vuex from "vuex";
import APIHealthPress from "../store/axios_api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ModalLogin: false,
    ModalProvider: false,
    ModalDetailUser: false,
    DataMe: [],
    DataListUser: [],
    SetProfileLog: [],
    GetProfileLog: [],
    Carts: [],
  },
  mutations: {
    SetModalLogin(state) {
      state.ModalLogin = !state.ModalLogin;
    },
    SetModalProvider(state) {
      state.ModalProvider = !state.ModalProvider;
    },
    SetDataMe(state, data) {
      state.DataMe = data;
    },
    SetDataListUser(state, data) {
      state.DataListUser = data;
    },
    SetModalDetailUser(state) {
      state.ModalDetailUser = !state.ModalDetailUser;
    },
    SetProfileLogUser(state, data) {
      state.SetProfileLog = data;
    },
    GetProfileLogUser(state, data) {
      state.GetProfileLog = data;
    },
    SetCarts(state, data) {
      state.Carts = data;
    },
    AddCarts(state, data) {
      state.Carts.push(data);
    },
  },
  actions: {
    async GetDataMe(context, access) {
      var response = await APIHealthPress.GetDataMe(access);
      await context.commit("SetDataMe", response);
    },
    async GetListUser(context) {
      var response = await APIHealthPress.GetDataListUSer();
      await context.commit("SetDataListUser", response);
    },
    async ActionsSetProfileLog(context, access) {
      var response = await APIHealthPress.SetProfileLog(access);
      await context.commit("SetProfileLogUser", response);
    },
    async ActionsGetProfileLog(context, access) {
      console.log(access);
      var response = await APIHealthPress.GetProfileLog(access);
      await context.commit("GetProfileLogUser", response);
    },

    async ActionsSetCars(context, data) {
      console.log(data);
      await context.commit("SetCarts", data);
    },
    async ActionsAddCars(context, data) {
      console.log(data);
      await context.commit("AddCarts", data);
    },
  },
  modules: {},
});
