import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Antd from "ant-design-vue";
import VueMask from "v-mask";
import VueSweetalert2 from "vue-sweetalert2";
import "ant-design-vue/dist/antd.css";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VuePromptpayQr from "vue-promptpay-qr";
import VueQRCodeComponent from "vue-qrcode-component";
import VueTheMask from "vue-the-mask";
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import vueBacktotop from "./plugins/vue-backtotop";

Vue.use(VueTheMask);
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(VuePromptpayQr);
Vue.use(VueAxios, axios);
Vue.use(Antd);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();
new Vue({
  router,
  store,
  vuetify,
  vueBacktotop,
  render: (h) => h(App),
}).$mount("#app");

// export default new Vuetify({
//   icons: {
//     iconfont: 'fa',
//   },
// })
