<template>
  <v-app style="background-color: #ffffff" class="mb-10">
    <router-view />
    <!-- <v-bottom-nav app
        :active.sync="bottomNav"
        :value="true"
        fixed
        color="white"
      >
        <v-btn
          color="teal"
          flat
          value="recent"
        >
          <span>Recent</span>
          <v-icon>history</v-icon>
        </v-btn>
  
        <v-btn
          color="teal"
          flat
          value="favorites"
        >
          <span>Favorites</span>
          <v-icon>favorite</v-icon>
        </v-btn>
  
        <v-btn
          color="teal"
          flat
          value="nearby"
        >
          <span>Nearby</span>
          <v-icon>place</v-icon>
        </v-btn>
      </v-bottom-nav> -->
  </v-app>
</template>


<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
</style>
