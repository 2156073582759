import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/menuall",
    //     name: "menuall",
    //     component: () =>
    //         import ("@/views/menuall"),
    // },
    {
        path: "/",
        component: () =>
            import ("@/views/Homepage"),
        children: [{
                path: "/",
                name: "index",
                component: () =>
                    import ("@/views/index"),
            },

            {
                path: "/menuall",
                name: "menuall",
                component: () =>
                    import ("@/views/menuall"),
            },
            {
                path: "/lotterythai",
                name: "homepage",
                component: () =>
                    import ("@/components/Homepage/Homepage"),
            },
            {
                path: "/listUser",
                name: "listUser",
                component: () =>
                    import ("../components/ManageUser/UserProfile.vue"),
            },
            {
                path: "/safe",
                name: "safe",
                component: () =>
                    import ("../views/safe.vue"),
            },
            {
                path: "/contact",
                name: "contact",
                component: () =>
                    import ("../views/contact.vue"),
            },
            {
                path: "/cart",
                name: "cart",
                component: () =>
                    import ("../views/cart.vue"),
            },
            {
                path: "/payment",
                name: "payment",
                component: () =>
                    import ("../views/payment.vue"),
            },
            {
                path: "/transaction",
                name: "transaction",
                component: () =>
                    import ("../views/transaction.vue"),
            },
            {
                path: "/member",
                name: "member",
                component: () =>
                    import ("../views/member.vue"),
            },
            {
                path: "/lineProfile",
                name: "lineProfile",
                component: () =>
                    import ("../views/lineProfile.vue"),
            },
            {
                path: "/History",
                name: "History",
                component: () =>
                    import ("../views/History.vue"),
            },
            {
                path: "/payment2",
                name: "payment2",
                component: () =>
                    import ("../views/payment2.vue"),
            },
            {
                path: "/transactionDetail",
                name: "transactionDetail",
                component: () =>
                    import ("../views/transactionDetail.vue"),
            },
            {
                path: "/transactionPowerballDetail",
                name: "transactionPowerballDetail",
                component: () =>
                    import ("../views/transactionPowerballDetail.vue"),
            },
            {
                path: "/transactionLaoDetail",
                name: "transactionLaoDetail",
                component: () =>
                    import ("../views/transactionLaoDetail.vue"),
            },
            {
                path: "/powerBall",
                name: "powerBall",
                component: () =>
                    import ("../views/powerBall.vue"),
            },
            {
                path: "/powerBallAus",
                name: "powerBallAus",
                component: () =>
                    import ("../views/powerBallAus.vue"),
            },
            {
                path: "/eurojackpot",
                name: "eurojackpot",
                component: () =>
                    import ("../views/eurojackpot.vue"),
            },
            {
                path: "/megamillions",
                name: "megamillions",
                component: () =>
                    import ("../views/megamillions.vue"),
            },
            {
                path: "/minilotto",
                name: "minilotto",
                component: () =>
                    import ("../views/minilotto.vue"),
            },
            {
                path: "/lotterylao",
                name: "lotterylao",
                component: () =>
                    import ("../views/lotterylao.vue"),
            },
            {
                path: "/lotteryjapan",
                name: "lotteryjapan",
                component: () =>
                    import ("../views/lotteryjapan.vue"),
            },
            {
                path: "/faq-net",
                name: "faq-net",
                component: () =>
                    import ("../views/faq-net.vue"),
            },
            {
                path: "/conditions-net",
                name: "conditions-net",
                component: () =>
                    import ("../views/conditions-net.vue"),
            },
            {
                path: "/result-blog",
                name: "result-blog",
                component: () =>
                    import ("../views/result-blog.vue"),
            },
            {
                path: "/dealers-net",
                name: "dealers-net",
                component: () =>
                    import ("../views/dealers-net.vue"),
            },
            {
                path: "/contact-net",
                name: "contact-net",
                component: () =>
                    import ("../views/contact-net.vue"),
            },
        ],
    },
    {
        path: "/redirect",
        name: "redirect",
        component: () =>
            import ("../views/redirect.vue"),
    },
    {
        path: "/powerBallWix",
        name: "powerBallWix",
        component: () =>
            import ("../views/powerBall.vue"),
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;